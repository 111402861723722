.slide-left-right {
    text-decoration:none;
    display: inline-block;
    color:#26a69a;
  }

  /* add a empty string after the elment with class .slide-left-right */
  .slide-left-right:after {
    content: '';
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width .5s ease, background-color .5s ease; /* .5 seonds for changes to the width and background-color */
    -webkit-transition: width .5s ease, background-color .5s ease; /* Chrome and Safari */
    -moz-transition: width .5s ease, background-color .5s ease; /* FireFox */
  }

  /* Change the width and background on hover, aka sliding out */
  .slide-left-right:hover:after {
    width: 100%;
    background: #26a69a;
  }

  .slide-left-right-white {
    text-decoration:none;
    display: inline-block;
    color:#444343;
    font-weight: 200;
  }

  /* add a empty string after the elment with class .slide-left-right */
  .slide-left-right-white:after {
    content: '';
    display: block;
    height: 2px;
    width: 0;
    background: transparent;
    transition: width .5s ease, background-color .5s ease; /* .5 seonds for changes to the width and background-color */
    -webkit-transition: width .5s ease, background-color .5s ease; /* Chrome and Safari */
    -moz-transition: width .5s ease, background-color .5s ease; /* FireFox */
  }

  /* Change the width and background on hover, aka sliding out */
  .slide-left-right-white:hover:after {
    width: 100%;
    background: #26a69a;
  }

  .email-button {
    text-decoration:none;
    display: inline-block;
    color:#18191b;
  }

mark.blue {
    color:#26a69a;
    background: none;
}